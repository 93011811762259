.buttons-calc {
    padding-top: 10px;
}

.buttons-calc button {
    width: 100%;
    color: white;
}

.g-2:before {
    content: "";
    width: 100%;
    display: block;
}

.g-2:after {
    content: "";
    margin-bottom: 21px;
    display: block;
    width: 100%;
}

@media (max-width: 800px) {
    .g-2 {
        flex-direction: column;
    }
}
