.App-result {
    margin-bottom: 15px;
    padding-bottom: 20px;
}

.App-result-low {
    font-size: 16px;
    text-align: left;
    display: inline-block;
}

.results {
    background: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    padding: 1rem;
    color: #6c757d;
}

.line-results {
    text-align: center;
    width: 100%;
    font-size: 30px;
}

.line-results:last-of-type:before {
    content: "";
    width: 100%;
    display: block;
    margin: 5px 0;
    border-top: 1px solid rgba(0,0,0,0.3);
}
