.App {
  text-align: center;
  max-width: 800px;
  margin: auto;
}

.App-logo {
  height: 40px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
    filter: brightness(0) invert(1);
  }
}

.App-header {
  background-color: #282c34;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-right: 15px;
}

.App-why {
  padding: 0;
}

.App-why-logo {
  width: 100%;
  max-width: 400px;
}

.App-why .accordion-button {
  background: #d6a206 !important;
  color: white !important;
  font-weight: bold;
}

.App-why .accordion-button.collapsed {
  background: #ffc108 !important;
  color: white !important;
  font-weight: bold;
}

.App-calculator-title {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.App-calculator-title:before {
  content: "";
  display: inline-block;
  width: 35%;
  background: rgba(0,0,0,0.1);
  height: 1px;
}
.App-calculator-title:after {
  content: "";
  display: inline-block;
  width: 35%;
  background: rgba(0,0,0,0.1);
  height: 1px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.App-link {
  color: #61dafb;
}

.why-body {
  text-align: justify;
}

.Well {
  width: 750px;
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
  color: #333;
}

.col-sm-12 > p {
  text-align: justify;
}

.form-label {
  width: 100%;
  margin-top: 5px;
  font-weight: bold;
}

.form-horizontal {
  background: rgb(29 172 207);
  border-radius: 3px;
  padding: 0 15px;
  margin-top: 20px;
  color: white;
}
