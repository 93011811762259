.App-disclaimer {
    background: #6d757d;
    color: #fff;
    margin-top: 20px;
}

.App-disclaimer-other {
    font-weight: bold;
}

.App-disclaimer-accordion .card-body {
    color: #6d757d;
    font-weight: bold;
}

.App-disclaimer-accordion .card-body a {
    font-weight: 500;
    color: #6d757d;
    font-size: 15px;
    padding-bottom: 5px;
    display: inline-block;
}

.App-disclaimer-separator {
    width: 100%;
    height: 1px;
    background: #fff;
    margin: 8px 0 10px 0;
    display: block;
}

.App-disclaimer-accordion button {
    background: #6d757d;
    color: white;
}

.App-disclaimer-content {
    width: 99%;
    margin: auto;
    text-align: center;
    font-size: 12px;
    padding: 10px 0;;
}
